import React, { useContext } from 'react';
import { appStore } from '../../state/app';
import Tabs from '../../components/Tabs';
import TabPane from '../../components/Tabs/TabPanel';
import List from '../../components/List';
import ProtocolInfo from '../../components/ProtocolInfo';
import Validators from '../../components/Validators';
import {
  navigationTabs, organizationsTabs, orgListTypes,
} from '../../utils/constants';
import CarbonProjects from '../CarbonProjects/CarbonProjects';
import ProjectSettings from '../../components/ProjectSettings';
import Permissions from '../../components/Permissions';

const Home = ({ page }) => {
  const { state } = useContext(appStore);
  const { account } = state;

  const renderPage = () => {
    switch (page) {
      case 'ApproveOrganizations':
        return (
          <TabPane name={navigationTabs.ApproveOrganizations} key="1">
            <Tabs>
              <TabPane name={organizationsTabs.waitingForApprove} key="1.1">
                <List verified />
              </TabPane>
              <TabPane name={organizationsTabs.approved} key="1.2">
                <List type={orgListTypes.approve} />
              </TabPane>
              <TabPane name={organizationsTabs.rejected} key="1.3">
                <List type={orgListTypes.reject} />
              </TabPane>
            </Tabs>
          </TabPane>
        );
      case 'ApproveCarbonProjects':
        return (
          <TabPane name={navigationTabs.ApproveCarbonProjects} key="2">
            <Tabs carbonTab isApprove="approve">
              <TabPane name="New" key="2.1">
                <CarbonProjects tab="new" />
              </TabPane>
              <TabPane name="Approved" key="2.2">
                <CarbonProjects tab="approve" />
              </TabPane>
              <TabPane name="Denied" key="2.3">
                <CarbonProjects tab="denied" />
              </TabPane>
            </Tabs>
          </TabPane>
        );
      case 'Validators':
        return (
          <TabPane name={navigationTabs.RegisterVal}>
            <Validators />
          </TabPane>
        );

      case 'ProtocolInfo':
        return (
          <TabPane name={navigationTabs.Projects}>
            <ProtocolInfo />
          </TabPane>
        );
      case 'ProjectSettings':
        return (
          <TabPane name={navigationTabs.ProjectSettings}>
            <ProjectSettings />
          </TabPane>
        );
      case 'Permissions':
        return (
          <TabPane name={navigationTabs.Permissions}>
            <Permissions />
          </TabPane>
        );
      default:
        return <h1>Page not found!</h1>;
    }
  };

  return (
    <>
      {account && (
        <Tabs account={account} horisontal isMainTabs>
          {renderPage()}
        </Tabs>
      )}
    </>
  );
};

export default Home;
