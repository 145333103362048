import React, {
  useState, useEffect, useContext,
} from 'react';
import { useLocation } from 'react-router';
import { CSVLink } from 'react-csv';
import TabImg from './TabImg';
import Modal from '../Modal/Modal';
import {
  checkTransaction,
  getHexTxFromUrl,
  getTxHashes,
  methodsNames,
} from '../../utils/near-utils';
import registerValidator from '../../services/registerValidator';
import Button from '../../generic/Button';
import Success from '../../assets/Success.svg';
import Failed from '../../assets/tabs/Failed';
import { navigationTabs, storageKeys } from '../../utils/constants';
import { appStore } from '../../state/app';
// import SearchInput from '../SearchInput';
import { makeCsvForOrganisation } from '../../utils/helpers';
import getAllProjects from '../../services/getAllProjects';

const Tabs = ({ children, horisontal, isMainTabs, account = null, carbonTab }) => {
  const [tabHeader, setTabHeader] = useState([]);
  const [childContent, setChildContent] = useState({});
  // const [searchValue, setSearchValue] = useState('');
  const [dataOrg, setDataOrg] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalValidatorReg, setModalValidatorReg] = useState({
    show: false,
    success: false,
  });
  const { state } = useContext(appStore);
  const [active, setActive] = useState('');
  const { pathname } = useLocation();
  const toCreateValidatorData = localStorage.getItem(storageKeys.toCreateValidatorData);
  let toCreateValidatorAccParsed;
  if (toCreateValidatorData) {
    toCreateValidatorAccParsed = JSON.parse(toCreateValidatorData);
  }
  const checkSignedTransaction = (hash) => {
    checkTransaction(hash, account.accountId).then((data) => {
      const transaction = data.transaction;
      const methodName = transaction?.actions[0]?.FunctionCall?.method_name;

      if (methodName === methodsNames.register_account_with_permissions && toCreateValidatorAccParsed) {
        (async () => {
          await registerValidator(
            toCreateValidatorAccParsed,
            () => setModalValidatorReg({
              show: true,
              success: true,
            }),
            () => setModalValidatorReg({
              show: true,
              success: false,
            }),
          );
        })();
      }
    });
  };
  useEffect(() => {
    if (active === 'Approved') {
      (async () => {
        setIsLoading(true);
        const token = localStorage.getItem(storageKeys.token);
        if (token) {
          const projData = await getAllProjects();
          setDataOrg(projData);
        }
        setIsLoading(false);
      })();
    }
  }, [active]);
  const onCloseValModal = () => setModalValidatorReg({ show: false, success: false });

  useEffect(() => {
    const headers = [];
    const childCnt = {};
    React.Children.forEach(children, (element) => {
      if (!React.isValidElement(element)) return;
      const { name } = element.props;
      headers.push(name);
      childCnt[name] = element.props.children;
      setTabHeader(headers);
      setActive(headers[0]);
      setChildContent({ ...childCnt });
    });
  }, []);

  useEffect(() => {
    const txHashes = getTxHashes();
    const currentHash = txHashes && getHexTxFromUrl(txHashes);

    if (toCreateValidatorAccParsed && isMainTabs) {
      if (currentHash) {
        checkSignedTransaction(currentHash);
      } else if (toCreateValidatorAccParsed) {
        setModalValidatorReg({
          show: true,
          success: false,
        });
      }
      localStorage.setItem(storageKeys.toCreateValidatorData, '');
      window.history.replaceState({}, '', window.location.origin + pathname);
      setActive(navigationTabs.RegisterVal);
    }
  }, []);
  const csvOrg = makeCsvForOrganisation(dataOrg);
  return (
    <div className={`tabs ${horisontal ? 'tabs-horisontal' : ''}`}>
      {!horisontal && (
        <div className="tabs__menu-tab">
          <ul className={`tabs-header ${!horisontal ? 'tabs-header_horisontal' : ''}`}>
            {tabHeader.map((item) => (
              <li
                onClick={() => setActive(item)}
                key={item}
                className={`tabs-link ${item === active ? 'active' : ''} ${!horisontal ? 'tabs-link_horisontal' : ''}`}
              >
                {horisontal && (
                <div className="tabs-link_img">
                  <TabImg name={item} active={active} />
                </div>
                )}
                {item}
                {!horisontal && carbonTab && item === 'Approved' && !!state?.counterZones && (
                <span className="tabs-link__counter">
                  {state?.counterZones}
                  {' '}
                </span>
                )}
                {' '}
              </li>
            ))}
          </ul>
          {active === 'Approved' && (
          <div className="tabs__left-menu">
            {/* <SearchInput onChange={setSearchValue} value={searchValue} /> */}
            <div className="tabs__download-block">
              <CSVLink
                data={
                  csvOrg
                }
                filename="Organization-Approve"
                className={`tabs__csv btn ${isLoading ? 'download' : ''}`}
              >
                {isLoading ? 'Loading...' : 'Download CSV'}
              </CSVLink>
            </div>
          </div>
          )}

        </div>
      )}

      <div className={horisontal ? 'tab-content' : 'tab-gap'}>
        {Object.keys(childContent).map((key) => {
          if (key === active) {
            return (
              <div className="tab-child" key={key}>
                {childContent[key]}
              </div>
            );
          }
          return null;
        })}
      </div>
      <Modal show={modalValidatorReg.show}>
        <div className="val-modal__container">
          <span className="val-modal__title">{modalValidatorReg?.success ? 'Success!' : 'Failure!'}</span>
          <div className="val-modal__desc">
            {modalValidatorReg.success ? <img src={Success} className="val-modal__icon" alt="icon" /> : <Failed />}
            <span className="val-modal__msg">
              {modalValidatorReg?.success ? 'Success! Validator has been created.' : 'Validator creation failure!'}
            </span>
          </div>
          <Button onClick={onCloseValModal} className="val-modal__button">
            <span className="val-modal__button-title">Ok</span>
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Tabs;
